<template>
  <div>
    <Card :bordered="false" dis-hover class="ivu-mt">
      <div class="box" ref="picBox">
        <UploadPictures v-if="uploadShow" :isShow="0" :isPage="true" :pageLimit="pageLimit"></UploadPictures>
      </div>
    </Card>
  </div>
</template>

<script>

export default {
  name: 'index',
  mixins: [],
  data () {
    return {
      pageLimit: 30,
      uploadShow: false
    }
  },
  components: {},
  // 支柱道具属性
  props: {},
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created () {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted () {
    this.$nextTick(() => {
      let winWidth = this.$refs.picBox.clientWidth
      let winHeight = document.body.clientHeight - 170
      if (winWidth < 1018) {
        this.pageLimit = 12
        this.pageLimit = winHeight > 790 ? 12 : 16
      } else if (winWidth < 1185) {
        this.pageLimit = winHeight > 790 ? 15 : 24
      } else if (winWidth < 1222) {
        this.pageLimit = 15
      } else if (winWidth < 1327) {
        this.pageLimit = 18
      } else if (winWidth < 1750) {
        this.pageLimit = 21
      } else if (winWidth < 2100) {
        this.pageLimit = winHeight > 790 ? 30 : 26
      } else if (winWidth > 2100) {
        this.pageLimit = winHeight > 790 ? 36 : 26
      }
      this.uploadShow = true
    })
  },
  // 方法集合
  methods: {},
  // 生命周期 - 更新之前
  beforeUpdate () {
  },
  // 生命周期 - 更新之后
  updated () {
  },
  // 过滤器
  filters: {},
  // 生命周期 - 创建之前
  beforeCreate () {
  },
  // 生命周期 - 挂载之前
  beforeMount () {
  },
  // 生命周期 - 销毁之前
  beforeDestroy () {
  },
  // 生命周期 - 销毁完成
  destroyed () {
  },
  // 如果页面有 keep-alive 缓存功能,这个函数会触发
  // 进入的时候触发
  activated () {
  },
  // 离开的时候触发
  deactivated () {
  }
}
</script>

<style scoped lang="scss">
.box {
  width: 100%;
  height: 100%;
  background: #fff;
}
/deep/ .ivu-card-body{
  min-height: 700px;
}
/deep/ .conter .pictrueList{
  max-width: 100%;
}
</style>
